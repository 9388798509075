import React from 'react';
import { amber } from "material-colors";

import loginEnImgPath from '../../../static/img/login-en.png';
import dataCenterEnImgPath from '../../../static/img/data-center-datasets-en.png';
import pageStructureEnImgPath from '../../../static/img/page-structure-en.png';
import dashboardEnImgPath from '../../../static/img/dashboard-en.png';

class MainFactsSection extends React.Component  {
    render() {
        const { t } = this.props;
        const styles = {
            section: {
                padding: '40px 10px'
            }
        };

        return (
            <div className="container" style={styles.section}>
                <div className="row mb30">
                    <div className="col-12">
                        <h2 style={{ fontWeight: 'bold' }}>{t('setupInMinutesSection.msg1')}</h2>
                        <h5>{t('setupInMinutesSection.msg2')}</h5>
                        <h5>{t('setupInMinutesSection.msg3')}</h5>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-sm-6 mb40">
                        <div style={{
                            display: 'flex',
                            marginBottom: 10
                        }}>
                            <div
                                style={{
                                    width: 40,
                                    color: amber[500],
                                    fontSize: 30,
                                    fontWeight: 'bold',
                                    marginRight: 10,
                                    textAlign: 'center'
                                }}
                            >
                                1
                            </div>
                            <div
                                style={{
                                    fontSize: 24,
                                    fontWeight: 'bold',
                                    lineHeight: '45px'
                                }}
                            >
                                {t('setupInMinutesSection.msg4')}
                            </div>
                        </div>
                        <div style={{
                            textAlign: 'center'
                        }}>
                            <img
                                src={loginEnImgPath}
                                width={'90%'}
                                alt={t('setupInMinutesSection.msg4')}
                                style={{
                                    border: '1px solid #999',
                                    padding: 2
                                }}
                            />
                        </div>
                    </div>
                    <div className="col-12 col-sm-6 mb40">
                        <div style={{
                            display: 'flex',
                            marginBottom: 10
                        }}>
                            <div
                                style={{
                                    width: 40,
                                    color: amber[500],
                                    fontSize: 30,
                                    fontWeight: 'bold',
                                    marginRight: 10,
                                    textAlign: 'center'
                                }}
                            >
                                2
                            </div>
                            <div
                                style={{
                                    fontSize: 24,
                                    fontWeight: 'bold',
                                    lineHeight: '45px'
                                }}
                            >
                                {t('setupInMinutesSection.msg5')}
                            </div>
                        </div>
                        <div style={{
                            textAlign: 'center'
                        }}>
                            <img
                                src={dataCenterEnImgPath}
                                width={'90%'}
                                alt={t('setupInMinutesSection.msg5')}
                                style={{
                                    border: '1px solid #999',
                                    padding: 2
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-sm-6 mb40">
                        <div style={{ display: 'flex', marginBottom: 10 }}>
                            <div
                                style={{
                                    width: 40,
                                    color: amber[500],
                                    fontSize: 30,
                                    fontWeight: 'bold',
                                    marginRight: 10,
                                    textAlign: 'center'
                                }}
                            >
                                3
                            </div>
                            <div
                                style={{
                                    fontSize: 24,
                                    fontWeight: 'bold',
                                    lineHeight: '45px'
                                }}
                            >
                                {t('setupInMinutesSection.msg6')}
                            </div>
                        </div>
                        <div style={{
                            textAlign: 'center'
                        }}>
                            <img
                                src={pageStructureEnImgPath}
                                width={'90%'}
                                alt={t('setupInMinutesSection.msg6')}
                                style={{
                                    border: '1px solid #999',
                                    padding: 2
                                }}
                            />
                        </div>
                    </div>
                    <div className="col-12 col-sm-6 mb40">
                        <div style={{ display: 'flex', marginBottom: 10 }}>
                            <div
                                style={{
                                    width: 40,
                                    color: amber[500],
                                    fontSize: 30,
                                    fontWeight: 'bold',
                                    marginRight: 10,
                                    textAlign: 'center'
                                }}
                            >
                                4
                            </div>
                            <div
                                style={{
                                    fontSize: 24,
                                    fontWeight: 'bold',
                                    lineHeight: '45px'
                                }}
                            >
                                {t('setupInMinutesSection.msg7')}
                            </div>
                        </div>
                        <div style={{
                            textAlign: 'center'
                        }}>
                            <img
                                src={dashboardEnImgPath}
                                width={'90%'}
                                alt={t('setupInMinutesSection.msg7')}
                                style={{
                                    border: '1px solid #999',
                                    padding: 2
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default MainFactsSection;