import React from 'react';

import exportShareImgPath from '../../../static/img/biuwer-export-share.png';

class ExportShareSection extends React.Component {
    render() {
        const { t } = this.props;
        const styles = {
            listContainer: {
                display: 'flex',
                flexDirection: 'row'
            },
            img: {
                width: '800px',
                maxWidth: '100%'
            },
            iconContainer: {
                backgroundColor: '#fff',
                boxShadow: '0 4px 10px 2px #eaeaea',
                borderRadius: '50%',
                minWidth: 50,
                width: 50,
                height: 50,
                marginRight: 20,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            },
            icon: {
                fontSize: 28,
                color: '#2E5CA6'
            }
        };

        return (
            <section>
                <div className="container">
                    <div className="row mb30 pt40">
                        <div className="col-12 text-center">
                            <h2>{t('product.exportShare.msg1')}</h2>
                            <h4>{t('product.exportShare.msg2')}</h4>
                        </div>
                    </div>
                    <div className="row pb40">
                        <div className="col-12 col-lg-4">
                            <div className="mv20" style={styles.listContainer}>
                                <div style={styles.iconContainer}>
                                    <span className="bp3-icon-cloud-download bp3-icon-large bp3-intent-success" style={styles.icon}></span>
                                </div>
                                <div>
                                    <p className="mt5">{t('product.exportShare.msg3')}</p>
                                </div>
                            </div>
                            <div className="mv20" style={styles.listContainer}>
                                <div style={styles.iconContainer}>
                                    <span className="bp3-icon-document bp3-icon-large bp3-intent-success" style={styles.icon}></span>
                                </div>
                                <div>
                                    <p className="mt5">{t('product.exportShare.msg4')}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-8 pb40 text-center">
                            <img
                                src={exportShareImgPath}
                                style={styles.img}
                                alt={t('product.exportShare.msg2')}
                            />
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default ExportShareSection;