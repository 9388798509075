import React from 'react';
/** @jsx jsx */
import { jsx } from '@emotion/react';

import config from '../../../gatsby-config';
import productHeaderImg from '../../../static/img/achieve-data-driven-growth.svg';

class HeaderSection extends React.Component  {
    render() {
        const { t } = this.props;

        return (
            <header
                id="headerSection"
                key="header"
                css={styles.mainSection}
            >
                <div className="container-fluid pt30">
                    <div className="row text-center">
                        <div className="col-12 mb20">
                            <h1 style={{ color: 'white' }}>{t('product.header.msg1')}</h1>
                            <div style={{ marginTop: 20, marginBottom: 20 }}>
                                <img
                                    src={productHeaderImg}
                                    alt={t('product.header.msg1')}
                                    style={{ marginBottom: 30, maxHeight: 250, width: '100%' }}
                                />
                                <h3 style={{ color: 'white' }}>{t('product.header.msg2')}</h3>
                                <h5 style={{ color: 'white', fontWeight: 'lighter', marginBottom: 20 }}>{t('product.header.msg3')}</h5>
                                <h3 style={{ color: 'white' }}>{t('product.header.msg4')}</h3>
                                <h5 style={{ color: 'white', fontWeight: 'lighter' }}>{t('product.header.msg5')}</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        )
    }
}

const styles = {
    mainSection: {
        paddingTop: config.siteMetadata.navHeaderHeight,
        background: 'linear-gradient(#2E5CA6, #2E5CA6)'
    }
};

export default HeaderSection;