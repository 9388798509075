import React from 'react';

import pageFilterDialogImgPath from '../../../static/img/biuwer-page-filter-dialog.png';
import dateFilterDialogImgPath from '../../../static/img/biuwer-date-filter-dialog.png';

class ConnectorsSection extends React.Component {
    render() {
        const { t } = this.props;
        const styles = {
            listContainer: {
                display: 'flex',
                flexDirection: 'row'
            },
            img: {
                width: '800px',
                maxWidth: '100%',
                border: '1px solid #ccc'
            },
            iconContainer: {
                backgroundColor: '#fff',
                boxShadow: '0 4px 10px 2px #eaeaea',
                borderRadius: '50%',
                minWidth: 50,
                width: 50,
                height: 50,
                marginRight: 20,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            },
            icon: {
                fontSize: 28,
                color: '#2E5CA6'
            }
        };

        return (
            <section style={{ backgroundColor: '#F9FBFD' }}>
                <div className="container">
                    <div className="row mb30 pt40">
                        <div className="col-12 text-center">
                            <h2>{t('product.filters.msg1')}</h2>
                            <h4>{t('product.filters.msg2')}</h4>
                        </div>
                    </div>
                    <div className="row mb40">
                        <div className="col-12 col-lg-4">
                            <h5>{t('product.filters.msg3')}</h5>
                            <div className="mv20" style={styles.listContainer}>
                                <div style={styles.iconContainer}>
                                    <span className="bp3-icon-filter bp3-icon-large bp3-intent-success" style={styles.icon}></span>
                                </div>
                                <div>
                                    <p className="mt5">{t('product.filters.msg4')}</p>
                                </div>
                            </div>
                            <div className="mv20" style={styles.listContainer}>
                                <div style={styles.iconContainer}>
                                    <span className="bp3-icon-diagram-tree bp3-icon-large bp3-intent-success" style={styles.icon}></span>
                                </div>
                                <div>
                                    <p className="mt5">{t('product.filters.msg5')}</p>
                                </div>
                            </div>
                            <div className="mv20" style={styles.listContainer}>
                                <div style={styles.iconContainer}>
                                    <span className="bp3-icon-form bp3-icon-large bp3-intent-success" style={styles.icon}></span>
                                </div>
                                <div>
                                    <p className="mt5">{t('product.filters.msg6')}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-8 text-center">
                            <img
                                src={pageFilterDialogImgPath}
                                style={styles.img}
                                alt={t('product.filters.msg3')}
                            />
                        </div>
                    </div>
                    <div className="row pb40">
                        <div className="col-12 col-lg-4">
                            <h5>{t('product.filters.msg7')}</h5>
                            <div className="mv20" style={styles.listContainer}>
                                <div style={styles.iconContainer}>
                                    <span className="bp3-icon-multi-select bp3-icon-large bp3-intent-success" style={styles.icon}></span>
                                </div>
                                <div>
                                    <p className="mt5">{t('product.filters.msg8')}</p>
                                </div>
                            </div>
                            <div className="mv20" style={styles.listContainer}>
                                <div style={styles.iconContainer}>
                                    <span className="bp3-icon-property bp3-icon-large bp3-intent-success" style={styles.icon}></span>
                                </div>
                                <div>
                                    <p className="mt5">{t('product.filters.msg9')}</p>
                                </div>
                            </div>
                            <div className="mv20" style={styles.listContainer}>
                                <div style={styles.iconContainer}>
                                    <span className="bp3-icon-horizontal-bar-chart-desc bp3-icon-large bp3-intent-success" style={styles.icon}></span>
                                </div>
                                <div>
                                    <p className="mt5">{t('product.filters.msg10')}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-8 text-center">
                            <img
                                src={dateFilterDialogImgPath}
                                style={{ ...styles.img, maxWidth: '80%' }}
                                alt={t('product.filters.msg7')}
                            />
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default ConnectorsSection;