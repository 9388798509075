import React from 'react';
import { useTranslation } from 'react-i18next';

// Most of these logos have been downloaded from https://www.svgrepo.com/
import googleBigQueryImgPath from '../../../static/img/logos/logo-bigquery.svg';
import amazonRedshiftImgPath from '../../../static/img/logos/logo-redshift.svg';
import snowflakeImgPath from '../../../static/img/logos/logo-snowflake.svg';
import azureSqlImgPath from '../../../static/img/logos/logo-azure-sql-db.svg';
import mysqlImgPath from '../../../static/img/logos/logo-mysql.svg';
import postgresImgPath from '../../../static/img/logos/logo-postgres.svg';
import mongodbImgPath from '../../../static/img/logos/logo-mongodb.svg';
import sqlServerImgPath from '../../../static/img/logos/logo-microsoft-sql-server.svg';
import oracleImgPath from '../../../static/img/logos/logo-oracle.svg';
import sqliteImgPath from '../../../static/img/logos/logo-sqlite.svg';
import mariadbImgPath from '../../../static/img/logos/logo-mariadb.svg';
import googleCloudMysqlImgPath from '../../../static/img/logos/logo-google-cloud-sql-mysql.svg';
import googleCloudPostgresImgPath from '../../../static/img/logos/logo-google-cloud-sql-postgres.svg';
import ftpImgPath from '../../../static/img/logos/logo-ftp.svg';
import excelImgPath from '../../../static/img/logos/logo-excel.svg';
import csvImgPath from '../../../static/img/logos/logo-csv.svg';
import jsonImgPath from '../../../static/img/logos/logo-json.svg';
import googleSpreadsheetsImgPath from '../../../static/img/logos/logo-google-spreadsheets.svg';
import googleAnalyticsV4ImgPath from '../../../static/img/logos/logo-google-analytics-v4.svg';
import amazonS3ImgPath from '../../../static/img/logos/logo-amazon-s3.svg';
import clickhouseImgPath from '../../../static/img/logos/logo-clickhouse.svg';
import wooCommerceImgPath from '../../../static/img/logos/logo-woocommerce.svg';
import prestashopImgPath from '../../../static/img/logos/logo-prestashop.svg';
import hubspotImgPath from '../../../static/img/logos/logo-hubspot.svg';
import clockifyImgPath from '../../../static/img/logos/logo-clockify.svg';
import holdedImgPath from '../../../static/img/logos/logo-holded.svg';
import businessCentralCloudImgPath from '../../../static/img/logos/logo-business-central-cloud.svg';
import pipedriveImgPath from '../../../static/img/logos/logo-pipedrive.svg';
import apiRestBatchImgPath from '../../../static/img/logos/logo-api-rest-batch.svg';
import notionImgPath from '../../../static/img/logos/logo-notion.svg';
import googleAdsImgPath from '../../../static/img/logos/logo-google-ads.svg';
import facebookAdsImgPath from '../../../static/img/logos/logo-facebook-ads.svg';
import stripeImgPath from '../../../static/img/logos/logo-stripe.svg';
import webhooksImgPath from '../../../static/img/logos/logo-webhooks.svg';
import slackImgPath from '../../../static/img/logos/logo-slack.svg';
import linkedinImgPath from '../../../static/img/logos/logo-linkedin.svg';
import googleImgPath from '../../../static/img/logos/logo-google.svg';
import salesforceImgPath from '../../../static/img/logos/logo-salesforce.svg';
import zohoImgPath from '../../../static/img/logos/logo-zoho.svg';
import odooImgPath from '../../../static/img/logos/logo-odoo.svg';
import quickbooksImgPath from '../../../static/img/logos/logo-quickbooks.svg';
import xeroImgPath from '../../../static/img/logos/logo-xero.svg';
import aircallImgPath from '../../../static/img/logos/logo-aircall.svg';
import twilioImgPath from '../../../static/img/logos/logo-twilio.svg';
import shopifyImgPath from '../../../static/img/logos/logo-shopify.svg';
import customConnectorImgPath from '../../../static/img/logos/logo-custom-connector.svg';

/**
 * ConnectorBox component draws a box with connector details (image, text and the coming soon badge)
 * @param {imgPath, i18nString, isComingSoon} props 
 * @returns 
 */
const ConnectorBox = (props) => {
    const { t } = useTranslation();

    const styles = {
        imgBox: {
            textAlign: 'center',
            border: '1px solid #f0f0f0'
        },
        imgStyle: {
            padding: 20,
            height: 120,
            maxWidth: '100%'
        },
        badge: {
            position: 'absolute',
            marginTop: -15,
            marginLeft: 15
        }
    };

    return (
        <div className="col-12 col-md-3 mb30">
            <div style={styles.imgBox}>
                {props.isComingSoon && (
                <div style={styles.badge}>
                    <span className="badge bg-success">{t('product.comingSoon')}</span>
                </div>
                )}
                <img
                    src={props.imgPath}
                    alt={t(props.i18nString)}
                    style={styles.imgStyle}
                />
                <div className="mb10">
                    <span>{t(props.i18nString)}</span>
                </div>
            </div>
        </div>
    );    
}

const ConnectorsSection = () => {
    const { t } = useTranslation();
    
    return (
        <div className="container">
            <div className="row mb30 pt40">
                <div className="col-12 text-center">
                    <h2>{t('product.connectors.msg1')}</h2>
                    <h5>{t('product.connectors.msg2')}</h5>
                </div>
            </div>
            <div className="row">
                <ConnectorBox
                    styles
                    imgPath={postgresImgPath}
                    i18nString={'product.connectors.postgres'}
                    isComingSoon={false}
                />
                <ConnectorBox
                    styles
                    imgPath={mysqlImgPath}
                    i18nString={'product.connectors.mysql'}
                    isComingSoon={false}
                />
                <ConnectorBox
                    styles
                    imgPath={sqlServerImgPath}
                    i18nString={'product.connectors.sqlServer'}
                    isComingSoon={false}
                />
                <ConnectorBox
                    styles
                    imgPath={clickhouseImgPath}
                    i18nString={'product.connectors.clickhouse'}
                    isComingSoon={false}
                />          
            </div>
            <div className="row">
                <ConnectorBox
                    styles
                    imgPath={oracleImgPath}
                    i18nString={'product.connectors.oracle'}
                    isComingSoon={false}
                />
                <ConnectorBox
                    styles
                    imgPath={googleBigQueryImgPath}
                    i18nString={'product.connectors.googleBigQuery'}
                    isComingSoon={false}
                />
                <ConnectorBox
                    styles
                    imgPath={azureSqlImgPath}
                    i18nString={'product.connectors.azureSql'}
                    isComingSoon={false}
                />
                <ConnectorBox
                    styles
                    imgPath={mongodbImgPath}
                    i18nString={'product.connectors.mongodb'}
                    isComingSoon={false}
                />
            </div>
            <div className="row">
                <ConnectorBox
                    styles
                    imgPath={mariadbImgPath}
                    i18nString={'product.connectors.mariadb'}
                    isComingSoon={false}
                />
                <ConnectorBox
                    styles
                    imgPath={googleCloudPostgresImgPath}
                    i18nString={'product.connectors.googleCloudPostgres'}
                    isComingSoon={false}
                />
                <ConnectorBox
                    styles
                    imgPath={googleCloudMysqlImgPath}
                    i18nString={'product.connectors.googleCloudMysql'}
                    isComingSoon={false}
                />                
                <ConnectorBox
                    styles
                    imgPath={amazonRedshiftImgPath}
                    i18nString={'product.connectors.amazonRedshift'}
                    isComingSoon={true}
                />
            </div>
            <div className="row">
                <ConnectorBox
                    styles
                    imgPath={snowflakeImgPath}
                    i18nString={'product.connectors.snowflake'}
                    isComingSoon={true}
                />
                <ConnectorBox
                    styles
                    imgPath={sqliteImgPath}
                    i18nString={'product.connectors.sqlite'}
                    isComingSoon={true}
                />
                <ConnectorBox
                    styles
                    imgPath={csvImgPath}
                    i18nString={'product.connectors.csv'}
                    isComingSoon={false}
                />
                <ConnectorBox
                    styles
                    imgPath={excelImgPath}
                    i18nString={'product.connectors.excel'}
                    isComingSoon={false}
                />
            </div>
            <div className="row">
                <ConnectorBox
                    styles
                    imgPath={googleSpreadsheetsImgPath}
                    i18nString={'product.connectors.googleSpreadsheets'}
                    isComingSoon={false}
                />
                <ConnectorBox
                    styles
                    imgPath={jsonImgPath}
                    i18nString={'product.connectors.json'}
                    isComingSoon={false}
                />
                <ConnectorBox
                    styles
                    imgPath={apiRestBatchImgPath}
                    i18nString={'product.connectors.apiRestBatch'}
                    isComingSoon={false}
                />
                <ConnectorBox
                    styles
                    imgPath={webhooksImgPath}
                    i18nString={'product.connectors.webhooks'}
                    isComingSoon={true}
                />
            </div>
            <div className="row">
                <ConnectorBox
                    styles
                    imgPath={notionImgPath}
                    i18nString={'product.connectors.notion'}
                    isComingSoon={false}
                />
                <ConnectorBox
                    styles
                    imgPath={slackImgPath}
                    i18nString={'product.connectors.slack'}
                    isComingSoon={true}
                />
                <ConnectorBox
                    styles
                    imgPath={stripeImgPath}
                    i18nString={'product.connectors.stripe'}
                    isComingSoon={true}
                />                
                <ConnectorBox
                    styles
                    imgPath={ftpImgPath}
                    i18nString={'product.connectors.ftp'}
                    isComingSoon={true}
                />
            </div>
            <div className="row">
                <ConnectorBox
                    styles
                    imgPath={hubspotImgPath}
                    i18nString={'product.connectors.hubspot'}
                    isComingSoon={false}
                />
                <ConnectorBox
                    styles
                    imgPath={pipedriveImgPath}
                    i18nString={'product.connectors.pipedrive'}
                    isComingSoon={false}
                />
                <ConnectorBox
                    styles
                    imgPath={salesforceImgPath}
                    i18nString={'product.connectors.salesforce'}
                    isComingSoon={true}
                />
                <ConnectorBox
                    styles
                    imgPath={zohoImgPath}
                    i18nString={'product.connectors.zoho'}
                    isComingSoon={true}
                />
            </div>
            <div className="row">
                <ConnectorBox
                    styles
                    imgPath={businessCentralCloudImgPath}
                    i18nString={'product.connectors.businessCentralCloud'}
                    isComingSoon={false}
                />
                <ConnectorBox
                    styles
                    imgPath={holdedImgPath}
                    i18nString={'product.connectors.holded'}
                    isComingSoon={false}
                />
                <ConnectorBox
                    styles
                    imgPath={xeroImgPath}
                    i18nString={'product.connectors.xero'}
                    isComingSoon={true}
                />
                <ConnectorBox
                    styles
                    imgPath={quickbooksImgPath}
                    i18nString={'product.connectors.quickbooks'}
                    isComingSoon={true}
                />
            </div>
            <div className="row">
                <ConnectorBox
                    styles
                    imgPath={odooImgPath}
                    i18nString={'product.connectors.odoo'}
                    isComingSoon={true}
                />
                <ConnectorBox
                    styles
                    imgPath={twilioImgPath}
                    i18nString={'product.connectors.twilio'}
                    isComingSoon={true}
                />
                <ConnectorBox
                    styles
                    imgPath={aircallImgPath}
                    i18nString={'product.connectors.aircall'}
                    isComingSoon={true}
                />
                <ConnectorBox
                    styles
                    imgPath={googleAnalyticsV4ImgPath}
                    i18nString={'product.connectors.googleAnalyticsV4'}
                    isComingSoon={false}
                />
                
            </div>
            <div className="row">
                <ConnectorBox
                    styles
                    imgPath={googleAdsImgPath}
                    i18nString={'product.connectors.googleAds'}
                    isComingSoon={false}
                />
                <ConnectorBox
                    styles
                    imgPath={googleImgPath}
                    i18nString={'product.connectors.googleMyBusiness'}
                    isComingSoon={true}
                />
                <ConnectorBox
                    styles
                    imgPath={googleImgPath}
                    i18nString={'product.connectors.googleSearchConsole'}
                    isComingSoon={true}
                />
                <ConnectorBox
                    styles
                    imgPath={facebookAdsImgPath}
                    i18nString={'product.connectors.facebookAds'}
                    isComingSoon={false}
                />
            </div>
            <div className="row">
                <ConnectorBox
                    styles
                    imgPath={linkedinImgPath}
                    i18nString={'product.connectors.linkedinPages'}
                    isComingSoon={true}
                />
                <ConnectorBox
                    styles
                    imgPath={linkedinImgPath}
                    i18nString={'product.connectors.linkedinAds'}
                    isComingSoon={true}
                />
                <ConnectorBox
                    styles
                    imgPath={clockifyImgPath}
                    i18nString={'product.connectors.clockify'}
                    isComingSoon={false}
                />
                
                <ConnectorBox
                    styles
                    imgPath={amazonS3ImgPath}
                    i18nString={'product.connectors.amazonS3'}
                    isComingSoon={true}
                />
            </div>
            <div className="row pb40">
                <ConnectorBox
                    styles
                    imgPath={shopifyImgPath}
                    i18nString={'product.connectors.shopify'}
                    isComingSoon={true}
                />
                <ConnectorBox
                    styles
                    imgPath={wooCommerceImgPath}
                    i18nString={'product.connectors.wooCommerce'}
                    isComingSoon={false}
                />
                <ConnectorBox
                    styles
                    imgPath={prestashopImgPath}
                    i18nString={'product.connectors.prestashop'}
                    isComingSoon={false}
                />
                <ConnectorBox
                    styles
                    imgPath={customConnectorImgPath}
                    i18nString={'product.connectors.customConnector'}
                    isComingSoon={true}
                />
            </div>
        </div>
    )
}

export default ConnectorsSection;