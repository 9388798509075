import React from 'react';

import dataCenterImgPath from '../../../static/img/biuwer-data-center.png';

class DataCenterSection extends React.Component {
    render() {
        const { t } = this.props;
        const styles = {
            listContainer: {
                display: 'flex',
                flexDirection: 'row'
            },
            img: {
                width: '800px',
                maxWidth: '100%',
                border: '1px solid #ccc'
            },
            iconContainer: {
                backgroundColor: '#fff',
                boxShadow: '0 4px 10px 2px #eaeaea',
                borderRadius: '50%',
                minWidth: 50,
                width: 50,
                height: 50,
                marginRight: 20,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            },
            icon: {
                fontSize: 28,
                color: '#2E5CA6'
            }
        };

        return (
            <section style={{ backgroundColor: '#F9FBFD' }}>
                <div className="container">
                    <div className="row mb30 pt40">
                        <div className="col-12 text-center">
                            <h2>{t('product.dataCenter.msg1')}</h2>
                            <h4>{t('product.dataCenter.msg2')}</h4>
                        </div>
                    </div>
                    <div className="row pb40">
                        <div className="col-12 col-lg-8 pb40 text-center">
                            <h3>{t('product.dataCenter.msg3')}:</h3>
                            <h4 className="mb40">{t('product.dataCenter.msg4')}</h4>
                            <img
                                src={dataCenterImgPath}
                                style={styles.img}
                                alt={t('product.dataCenter.msg2')}
                            />
                        </div>
                        <div className="col-12 col-lg-4">
                            <div className="mv20" style={styles.listContainer}>
                                <div style={styles.iconContainer}>
                                    <span className="bp3-icon-database bp3-icon-large bp3-intent-success" style={styles.icon}></span>
                                </div>
                                <div>
                                    <h6 className="mt5">{t('product.dataCenter.msg5')}</h6>
                                    <p>{t('product.dataCenter.msg6')}</p>
                                </div>
                            </div>
                            <div className="mv20" style={styles.listContainer}>
                                <div style={styles.iconContainer}>
                                    <span className="bp3-icon-th bp3-icon-large bp3-intent-success" style={styles.icon}></span>
                                </div>
                                <div>
                                    <h6 className="mt5">{t('product.dataCenter.msg7')}</h6>
                                    <p>{t('product.dataCenter.msg8')}</p>
                                </div>
                            </div>
                            <div className="mv20" style={styles.listContainer}>
                                <div style={styles.iconContainer}>
                                    <span className="bp3-icon-right-join bp3-icon-large bp3-intent-success" style={styles.icon}></span>
                                </div>
                                <div>
                                    <h6 className="mt5">{t('product.dataCenter.msg9')}</h6>
                                    <p>{t('product.dataCenter.msg10')}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default DataCenterSection;