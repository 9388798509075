import React from 'react';
import { useTranslation } from 'react-i18next';

// Components
import MainLayout from '../../components/main-layout';
import HeaderSection from './header-section';
import ContentSection from './content-section';
import MainFactsSection from "./main-facts-section";
import DataFiltersSection from './data-filters-section';
import ConnectorsSection from './connectors-section';
import DataCenterSection from './data-center-section';
import ExportShareSection from './export-share-section';
import SecuritySection from './security-section';
import RequestDemoSection from '../../components/RequestDemoSection';

const Product = (props) => {
    const { t, i18n } = useTranslation(),
        { pageContext } = props

    return (
        <MainLayout
            pageContext={pageContext}
        >
            <HeaderSection key="product-header-section" {...props} t={t}/>
            <ContentSection key="content-section" {...props} t={t}/>
            <MainFactsSection key="main-facts-section" {...props} t={t} i18n={i18n} />
            <DataFiltersSection key="data-filters-section" {...props} t={t}/>
            <ConnectorsSection key="connectors-section" {...props} t={t}/>
            <DataCenterSection key="data-center-section" {...props} t={t}/>
            <ExportShareSection key="export-share-section" {...props} t={t}/>
            <SecuritySection key="security-section" {...props} t={t}/>
            <RequestDemoSection key="request-demo-section" {...props} t={t} i18n={i18n}/>
        </MainLayout>
    )
}

export default Product