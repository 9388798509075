import React from 'react';

import dataCenterDataPoliciesImgPath from '../../../static/img/biuwer-data-center-data-policies.png';
import accessSettingsDialogImgPath from '../../../static/img/biuwer-access-settings-dialog.png';

class ConnectorsSection extends React.Component {
    render() {
        const { t } = this.props;
        const styles = {
            listContainer: {
                display: 'flex',
                flexDirection: 'row'
            },
            img: {
                width: '800px',
                maxWidth: '100%',
                border: '1px solid #ccc',
                marginBottom: 30
            },
            iconContainer: {
                backgroundColor: '#fff',
                boxShadow: '0 4px 10px 2px #eaeaea',
                borderRadius: '50%',
                minWidth: 50,
                width: 50,
                height: 50,
                marginRight: 20,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            },
            icon: {
                fontSize: 28,
                color: '#2E5CA6'
            }
        };

        return (
            <section style={{ backgroundColor: '#F9FBFD' }}>
                <div className="container">
                    <div className="row mb30 pt40">
                        <div className="col-12 text-center">
                            <h2>{t('product.security.msg1')}</h2>
                            <h4>{t('product.security.msg2')}&nbsp;<strong>{t('product.security.msg3')}</strong></h4>
                        </div>
                    </div>
                    <div className="row pb40">
                        <div className="col-12 col-lg-6">
                            <img
                                src={accessSettingsDialogImgPath}
                                style={styles.img}
                                alt={t('product.security.msg4')}
                            />
                            <h5 className="text-center">{t('product.security.msg4')}</h5>
                            <div className="mb20" style={styles.listContainer}>
                                <div style={styles.iconContainer}>
                                    <span className="bp3-icon-lock bp3-icon-large bp3-intent-success" style={styles.icon}></span>
                                </div>
                                <div className="mt5">
                                    <p>{t('product.security.msg5')}</p>
                                </div>
                            </div>
                            <div className="mb20" style={styles.listContainer}>
                                <div style={styles.iconContainer}>
                                    <span className="bp3-icon-people bp3-icon-large bp3-intent-success" style={styles.icon}></span>
                                </div>
                                <div className="mt5">
                                    <p>{t('product.security.msg6')}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6">
                            <img
                                src={dataCenterDataPoliciesImgPath}
                                style={styles.img}
                                alt={t('product.security.msg7')}
                            />
                            <h5 className="text-center">{t('product.security.msg7')}</h5>
                            <div className="mb20" style={styles.listContainer}>
                                <div style={styles.iconContainer}>
                                    <span className="bp3-icon-shield bp3-icon-large bp3-intent-success" style={styles.icon}></span>
                                </div>
                                <div className="mt5">
                                    <p>{t('product.security.msg8')}</p>
                                </div>
                            </div>
                            <div style={styles.listContainer}>
                                <div style={styles.iconContainer}>
                                    <span className="bp3-icon-series-filtered bp3-icon-large bp3-intent-success" style={styles.icon}></span>
                                </div>
                                <div className="mt5">
                                    <p>{t('product.security.msg9')}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default ConnectorsSection;